import FormErrors from './FormErrors'
import Application from 'App/Foundation/Application'

/**
 * Form manager.
 *
 * @author Alejandro Sanchez <asanchez@claruscare.com>
 */
export default class Form {
	/**
	 * Make a new Form instance.
	 *
	 * @param {Object} form
	 */
	constructor(form) {
		this.original = {}
		this.busy = false
		this.booted = false
		this.success = false
		this.beforeCallbacks = []
		this.handler = this._getDefaultHandler()
		this.http = Application.getInstance().http()

		if (form) {
			this.setFields(form)
			this.make(form)
		}

		return this
	}

	/**
	 * Allow the form class to handle custom data transformations with a callback.
	 *
	 * @param {Function}
	 * @return {Form}
	 */
	before(callback) {
		this.beforeCallbacks.push(callback)

		return this
	}

	/**
	 * Build the form's data object.
	 *
	 * @return {Object}
	 */
	data() {
		let data = {}

		this.fields.forEach(field => {
			data[field] = this.get(field)
		})

		this.beforeCallbacks.forEach(callback => {
			data = callback(data)
		})

		return data
	}

	/**
	 * Get the given field's form data.
	 *
	 * @param {String} field
	 * @return {String}
	 */
	get(field) {
		if (this[field]) {
			return this[field]
		}

		if (this.original[field] !== undefined) {
			return this.original[field]
		}

		return null
	}

	/**
	 * Determine if the form has any form changes.
	 *
	 * @return  {Boolean}
	 */
	hasChanged() {
		return this.fields.some(field => {
			return this.get(field) != this.original[field]
		})
	}

	/**
	 * Determine if the form has any form errors.
	 *
	 * @return {Boolean}
	 */
	hasErrors() {
		return this.errors.clear()
	}

	/**
	 * Assign date to a Form instance.
	 *
	 * @param {Object} form
	 * @return {Form}
	 */
	make(form) {
		if (!this.booted) {
			this.booted = true
			this.original = { ...form }
			this.errors = new FormErrors(form)
			Object.assign(this, form)
		}

		return this
	}

	/**
	 * Handle the error response.
	 *
	 * @param {ErrorResponse} response
	 * @return {Form}
	 */
	onError(response) {
		if (response && response.hasErrors && response.hasErrors()) {
			this.errors.fill(response.errors())
		}

		this.busy = false
		this.success = false

		return this
	}

	/**
	 * Handle the on submit event.
	 *
	 * @return {Form}
	 */
	onSubmit() {
		this.busy = true
		this.success = false

		this.errors.clear()

		return this
	}

	/**
	 * Handle the success response.
	 *
	 * @param {SuccessResponse} response
	 * @return {Form}
	 */
	onSuccess() {
		this.success = true
		this.busy = false

		return this
	}

	/**
	 * Restore the form to its original state.
	 *
	 * @return {Form}
	 */
	reset() {
		this.busy = false
		this.success = false
		this.errors.clear()

		Object.assign(this, this.original)

		return this
	}

	/**
	 * Set the given form field's data.
	 *
	 * @param {String} field
	 * @param {Array} errors
	 * @return {FormErrors}
	 */
	set(field, value) {
		this[field] = value

		if (this.errors.has(field)) {
			this.errors.clear(field)
		}

		return this
	}

	/**
	 * Set the form field names/keys.
	 *
	 * @param {Object} form
	 * @return {Array}
	 */
	setFields(form) {
		this.fields = Object.keys(form)
	}

	/**
	 * Set the form's submit handler.
	 *
	 * @param {Function} callback
	 * @return {Form}
	 */
	setSubmitHandler(callback) {
		this.handler = callback

		return this
	}

	/**
	 * Attempt to submit a form.
	 *
	 * @param {String} url
	 * @param {String} requestType
	 * @return {Promise}
	 */
	async submit(url, requestType = 'post') {
		if (this.busy) {
			return
		}

		this.onSubmit()

		try {
			const response = await this.handler(url, requestType, this.data())

			this.onSuccess()

			return response
		} catch (e) {
			this.onError(e)

			return Promise.reject(e)
		}
	}

	/**
	 * Perform the default API handler request.
	 *
	 * @return {Promise}
	 */
	_getDefaultHandler() {
		return (url, requestType) => this._send(url, requestType)
	}

	/**
	 * Attempt to submit a request.
	 *
	 * @param {String} url
	 * @param {String} requestType
	 * @return {Promise}
	 */
	_send(url, requestType) {
		return this.http[requestType](url, this.data())
	}
}
