import Form from './Form'

/**
 * User Edit form manager.
 *
 * @author Alejandro Sanchez <asanchez@claruscare.com>
 */
export default class UserEditForm extends Form {
	/**
	 * Create a new UserEditForm instance.
	 */
	constructor() {
		super(UserEditForm.getFields())
	}

	/**
	 * Fill the form with the partner's information.
	 *
	 * @return {void}
	 */
	fillForm(user) {
		if (!user) {
			return
		}

		this.fields.forEach(key => (this[key] = user[key]))

		this.partners = user?.partners.map(partner => {
			return partner.id
		})

		this.roles = user?.roles.map(role => {
			return {
				id: role.id,
				name: role.name,
				partner_id: role.partner_id,
			}
		})

		this.original = { ...this }
	}

	/**
	 * Get the default form fields.
	 *
	 * @return {Object}
	 */
	static getFields() {
		return {
			first_name: '',
			last_name: '',
			email: '',
			email_confirmation: '',
			partners: [],
			roles: [],
		}
	}
}
