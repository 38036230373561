<template>
	<div class="user-show">
		<div class="flex justify-between">
			<div>
				<router-link
					:to="{ name: 'app.users' }"
					class="user-previous-btn"
				>
					<span>
						<font-awesome-icon
							fixed-width
							:icon="['far', 'arrow-to-left']"
							aria-hidden="true"
						/>
					</span>
					Back
				</router-link>
			</div>
			<div class="user-actions-btn">
				<user-actions-dropdown :user="user" />
			</div>
		</div>
		<div class="user-container">
			<user-edit-form :user="user" />
			<user-password-form />
		</div>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import UserEditForm from '@/components/Forms/UserEditForm.vue'
import UserPasswordForm from '@/components/Forms/UserPasswordForm.vue'
import UserActionsDropdown from '@/components/UserActionsDropdown.vue'

export default {
	/**
	 * The component's registered child components.
	 *
	 * @type {Object}
	 */
	components: {
		UserEditForm,
		UserPasswordForm,
		UserActionsDropdown,
	},

	/**
	 * The component's computed properties.
	 *
	 * @type {Object}
	 */
	computed: {
		...mapGetters({
			find: 'users/find',
			partner: 'partners/active',
		}),
	},

	/**
	 * The component's local methods.
	 *
	 * @type {Object}
	 */
	methods: {
		/**
		 * Fetch a user by id.
		 *
		 * @return {Promise}
		 */
		async fetchUser() {
			try {
				const response = await this.getUser(this.$route.params.id)

				return response.get('data', {})
			} catch (e) {
				this.$alert.response(e)
			}
		},

		...mapActions({
			getUser: 'users/find',
		}),
	},

	/**
	 * The component's name used for debugging.
	 *
	 * @type {String}
	 */
	name: 'Show',

	/**
	 * The component's created lifecycle hook.
	 *
	 * @return {void}
	 */
	async created() {
		this.user = this.find(this.$route.params.id)

		if (!this.user) {
			this.user = await this.fetchUser()
		}
	},

	/**
	 * Get the component's initial state.
	 *
	 * @return {Object}
	 */
	data() {
		return {
			user: null,
		}
	},
}
</script>
