<template>
	<form
		class="user-section user-form"
		autocomplete="false"
		@submit.prevent="onSubmit"
	>
		<h1 class="user-name-title">{{ fullName }}</h1>
		<div class="form-group">
			<label for="firstName" class="form-label">
				First Name
			</label>
			<input
				id="firstName"
				v-model="form.first_name"
				type="text"
				required
				name="firstName"
				class="form-input"
				placeholder="First Name"
			/>
		</div>
		<div class="form-group">
			<label for="lastName" class="form-label">
				Last Name
			</label>
			<input
				id="lastName"
				v-model="form.last_name"
				required
				type="text"
				name="lastName"
				class="form-input"
				placeholder="Last Name"
			/>
		</div>
		<div class="form-group">
			<label for="email" class="form-label">
				Email
			</label>
			<input
				id="email"
				v-model="form.email"
				type="email"
				name="email"
				class="form-input"
				placeholder="Email"
			/>
		</div>
		<div class="form-group">
			<label for="confirmEmail" class="form-label">
				<p>Confirm Email *</p>
			</label>
			<input
				id="confirmEmail"
				v-model="form.email_confirmation"
				required
				type="email"
				name="confirmEmail"
				class="form-input mb-1"
				placeholder="Confirm Email"
			/>
			<span v-if="isInvalidForm" class="text-red-500">
				The email confirmation does not match
			</span>
		</div>
		<user-role-table
			:roles="roles"
			:partners="partners"
			@roles:change="onRolesChange"
		/>
		<div class="flex justify-end">
			<button
				type="submit"
				:disabled="!isValidEmail"
				class="btn btn-outline-success ml-2"
			>
				<font-awesome-icon
					class="btn-icon"
					:icon="['far', 'paper-plane']"
				/>
				<span class="btn-label">
					Save
				</span>
			</button>
		</div>
	</form>
</template>

<script>
import { mapActions } from 'vuex'
import UserEditForm from '@/app/Forms/UserEditForm'
import UserRoleTable from '@/components/UserRoleTable.vue'

/**
 * The user role value.
 *
 * @type {String}
 */
const USER_ROLE = 'user'

export default {
	/**
	 * The component's registered child components.
	 *
	 * @type {Object}
	 */
	components: {
		UserRoleTable,
	},

	/**
	 * The component's computed properties.
	 *
	 * @type {Object}
	 */
	computed: {
		/**
		 * Get the user's full name.
		 *
		 * @return {String}
		 */
		fullName() {
			if (!this.user) {
				return ''
			}

			return `${this.user.first_name} ${this.user.last_name}`
		},

		/**
		 * Determine if the form is valid.
		 *
		 * @return {Boolean}
		 */
		isInvalidForm() {
			const hasFormChanged = this.form.hasChanged()

			return hasFormChanged && !this.isValidEmail
		},

		/**
		 * Determine if both emails match.
		 *
		 * @return {Boolean}
		 */
		isValidEmail() {
			return this.form.email === this.form.email_confirmation
		},

		/**
		 * Get the user partners.
		 *
		 * @return {Array}
		 */
		partners() {
			return this.form.partners || []
		},

		/**
		 * Get the user roles.
		 *
		 * @return {Array}
		 */
		roles() {
			return this.form.roles || []
		},
	},

	/**
	 * The component's local methods.
	 *
	 * @type {Object}
	 */
	methods: {
		/**
		 * Fill the form with the partner's information.
		 *
		 * @return {void}
		 */
		fillForm() {
			if (!this.user) {
				return
			}

			this.form.fillForm(this.user)
		},

		/**
		 * Reset the form back to its initial state.
		 *
		 * @return {void}
		 */
		reset() {
			this.form.reset()
		},

		/**
		 * Handle the roles change event.
		 *
		 * @return {void}
		 */
		onRolesChange(roles) {
			this.form.partners = roles.map(role => role.partner_id)

			this.form.roles = roles.filter(role => role.id !== USER_ROLE)
		},

		/**
		 * Handle the on submit event.
		 *
		 * @return {void}
		 */
		async onSubmit() {
			try {
				const user = {
					id: this.user.id,
					...this.form.data(),
				}
				const response = await this.update(user)

				this.$alert.response(response)
			} catch (e) {
				this.$alert.response(e)
			}
		},

		...mapActions({
			update: 'users/update',
		}),
	},

	/**
	 * The component's name used for debugging.
	 *
	 * @type {String}
	 */
	name: 'UserEditForm',

	/**
	 * The component's inherited properties.
	 *
	 * @type {Object}
	 */
	props: {
		/**
		 * The user to be edited.
		 *
		 * @type {Object}
		 */
		user: {
			required: true,
			validator: prop => typeof prop === 'object' || prop === null,
		},
	},

	/**
	 * The component's property watchers.
	 *
	 * @type {Object}
	 */
	watch: {
		/**
		 * Watch the current route for active partner changes.
		 *
		 * @param {Object} to
		 * @param {Object} from
		 * @return {void}
		 */
		$route(to, from) {
			if (to.params.partner !== from.params.partner) {
				this.reset()

				this.$router.push({
					name: 'app.users',
				})
			}
		},

		/**
		 * Watch the user for changes.
		 *
		 * @type {Object}
		 */
		user: {
			immediate: true,
			handler: 'fillForm',
		},
	},

	/**
	 * Get the component's initial state.
	 *
	 * @return {Object}
	 */
	data() {
		return {
			confirmEmail: '',
			form: new UserEditForm(),
		}
	},
}
</script>
